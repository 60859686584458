<template>
  <div class="tenancy-agreement-landlord">
    <div class="sticky-element col-12 row p-2 mb-4">
      <h5 class="mb-2">Landlords</h5>

      <div class="d-flex justify-content-between w-100">
        <div class="row">
          <div class="toggle-tabs">
            <div
              v-for="(landlord, index) in existingLandlords"
              :key="index"
              class="tab d-inline-block"
            >
              <div class="mr-1">
                <fd-button
                  :class="{ secondary: activeLandlord == index }"
                  @click="activeLandlord = index"
                  >{{ getLandlordTabLabel(index) }}
                </fd-button>
                <fd-button
                  v-if="
                    activeLandlord == index &&
                    existingLandlords.length > 1 &&
                    index != 0
                  "
                  class="secondary bordered"
                  @click="removeLandlord(landlord.id, index)"
                >
                  <i class="fas fa-times"></i>
                </fd-button>
              </div>
            </div>
          </div>
          <fd-button
            v-if="landlordDetails.consumerType == CONSUMER_TYPE.JOINT"
            class="bordered main ml-2"
            @click="addLandlord"
          >
            <i class="fas fa-plus mr-1"></i>Add
          </fd-button>
        </div>
      </div>
    </div>
    <div class="row">
      <fd-form ref="taLandlordForm" class="col-12" @submit="saveLandlord">
        <!-- ========================== Tab Control ========================== -->

        <fd-form-section title="General">
          <fd-select
            v-model="landlordDetails.consumerType"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Consumer Type"
            :options="consumerTypeOptions"
            :validators="[...getIsFieldRequired()]"
          >
          </fd-select>
          <div
            v-if="landlordDetails.consumerType == CONSUMER_TYPE.COMPANY"
            class="col-12 row"
          >
            <fd-input
              v-model="landlordDetails.companyName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Company Name"
              name="TACompanyName"
              type="text"
              :validators="[...getIsFieldRequired()]"
            >
            </fd-input>
            <fd-input
              v-model="landlordDetails.companyNo"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Company No"
              name="TACompanyNo"
              type="text"
              :validators="[...getIsFieldRequired()]"
            >
            </fd-input>
            <div class="col-12 px-1 mb-2">
              <file-uploader
                v-model="landlordDetails.authorizationFiles"
                label="Authorization Evidence"
                :maxSize="5"
                :maxFile="5"
                multiple
                required
                :allowed="['pdf', 'jpg', 'png', 'jpeg']"
                accept=".pdf, image/jpeg, image/png"
                @isLoading="setIsLoading"
              ></file-uploader>
            </div>
            <div class="col-12 px-1 mb-2">
              <file-uploader
                v-model="landlordDetails.ssmFiles"
                label="SSM Files"
                :maxSize="5"
                :maxFile="10"
                multiple
                required
                :allowed="['pdf', 'jpg', 'png', 'jpeg']"
                accept=".pdf, image/jpeg, image/png"
                @isLoading="setIsLoading"
              ></file-uploader>
            </div>
          </div>

          <div class="col-12 px-1 mb-2">
            <file-uploader
              v-model="landlordDetails.otherDocuments"
              label="Other Documents"
              :maxSize="15"
              :maxFile="25"
              multiple
              :allowed="['pdf', 'jpg', 'png', 'jpeg']"
              accept=".pdf, image/jpeg, image/png"
              @isLoading="setIsLoading"
            ></file-uploader>
          </div>

          <div
            v-if="landlordDetails.consumerType == CONSUMER_TYPE.JOINT"
            class="col-12 px-1 mb-2"
          >
            <file-uploader
              v-model="landlordDetails.relationshipFiles"
              label="Relationship Evidence"
              :maxSize="15"
              :maxFile="15"
              multiple
              required
              :allowed="['pdf', 'jpg', 'png', 'jpeg']"
              accept=".pdf, image/jpeg, image/png"
              @isLoading="setIsLoading"
            ></file-uploader>
          </div>
        </fd-form-section>

        <fd-form-section title="Bank Account">
          <fd-select
            v-model="landlordDetails.bank"
            class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
            label="Bank"
            name="landlordBank"
            :optionKey="(val) => val.id"
            :optionValue="(val) => val.name"
            :options="bankOptions"
            :validators="[...getIsFieldRequired()]"
          ></fd-select>
          <fd-input
            v-model="landlordDetails.accountNumber"
            class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
            label="Bank Account No."
            name="landlordBankAccount"
            type="text"
            :validators="[...getIsFieldRequired()]"
          ></fd-input>
          <fd-input
            v-model="landlordDetails.accountName"
            class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
            label="Bank Account Name"
            name="landlordBankAccountName"
            type="text"
            :validators="[...getIsFieldRequired()]"
          ></fd-input>
        </fd-form-section>
      </fd-form>

      <!-- Form -->
      <fd-form
        ref="landlord"
        class="col-12"
        v-for="(landlord, index) in existingLandlords"
        v-show="activeLandlord == index"
        :key="index"
      >
        <fd-form-section title="Landlord Info">
          <fd-select
            v-if="
              landlordDetails.consumerType == CONSUMER_TYPE.JOINT && index !== 0
            "
            v-model="landlord.relationshipId"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Relationship With First Landlord"
            :isLoading="isRelationshipLoading"
            :options="relationshipOptions"
            :validators="[...getIsFieldRequired()]"
          >
          </fd-select>
          <fd-input
            v-model="landlord.name"
            class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
            label="Name"
            name="landlordName"
            type="text"
            :validators="[...getIsFieldRequired()]"
          ></fd-input>
          <fd-select
            v-model="landlord.icPassportType"
            class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
            label="Identification Type"
            :options="identificationTypeOptions"
            :validators="[...getIsFieldRequired()]"
          ></fd-select>
          <fd-input
            v-model="landlord.icPassport"
            class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
            :label="getIdNoLabel(landlord.icPassportType)"
            name="landlordIC"
            type="text"
            :validators="[...getIsFieldRequired()]"
          ></fd-input>

          <div
            v-if="landlord.icPassportType == IDENTIFICATION_TYPE.NRIC"
            class="col-12 px-1"
          >
            <image-uploader
              v-model="landlord.icFrontPhoto"
              class="mb-2"
              label="IC (Front)"
              :multiple="false"
              required
              @loading="setIsLoading"
              @error="
                (error, imageName) => {
                  $reportError(error, 'Upload TA Landlord IC (Front)');
                }
              "
            ></image-uploader>
            <image-uploader
              v-model="landlord.icBackPhoto"
              class="mb-2"
              label="IC (Back)"
              :multiple="false"
              required
              @loading="setIsLoading"
              @error="
                (error, imageName) => {
                  $reportError(error, 'Upload TA Landlord IC (Back)');
                }
              "
            ></image-uploader>
          </div>
          <div
            v-if="landlord.icPassportType == IDENTIFICATION_TYPE.PASSPORT"
            class="col-12 px-1"
          >
            <image-uploader
              v-model="landlord.passportPhoto"
              class="mb-2"
              label="Passport"
              multiple
              required
              :maxImage="2"
              @loading="setIsLoading"
              @error="
                (error, imageName) => {
                  $reportError(error, 'Upload TA Landlord Passport');
                }
              "
            ></image-uploader>
          </div>
          <fd-v-select
            v-model="landlord.nationality"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Nationality"
            :options="countryOptions"
            :optionLabel="(val) => val"
            :optionValue="(val) => val"
            filterable
            :validators="[...getIsFieldRequired()]"
          >
          </fd-v-select>
          <fd-input
            v-model="landlord.contact"
            class="col-12 sm-col-6 md-col-4 xl-col-3 px-1 mb-2"
            label="Contact No."
            name="landlordContactNo"
            type="text"
            :validators="[...getIsFieldRequired()]"
          ></fd-input>
          <fd-textarea
            v-model="landlord.address"
            class="col-12 px-1 mb-2"
            label="Address"
            name="landlordAddress"
            type="text"
          ></fd-textarea>
        </fd-form-section>
      </fd-form>
      <hr class="col-12 my-5" />
    </div>
  </div>
</template>

<script>
import { landlordDetail, userDetail } from "@/models/rentTA";
import {
  identificationTypeOptions,
  bankOptions,
  consumerTypeOptions,
  getIdNoLabel
} from "./TAUser";
import {
  required,
  phone
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
import {
  consumerType as CONSUMER_TYPE,
  identificationType as IDENTIFICATION_TYPE
} from "@/enums";
import { rentBooking as rentAPI } from "@/api";
import { RentTAModel } from "@/models";

export default {
  components: {
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader"),
    FileUploader: () => import("@/components/GlobalComponents/FileUploader")
  },
  mixins: [],
  props: {
    landlordData: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      activeLandlord: 0,

      existingLandlords: [landlordDetail],
      removedLandlords: [],

      landlordDetails: [landlordDetail],

      identificationTypeOptions: identificationTypeOptions,
      bankOptions: bankOptions,
      consumerTypeOptions: consumerTypeOptions,

      isCountryLoading: false,
      countryOptions: [],
      isRelationshipLoading: false,
      relationshipOptions: [],

      CONSUMER_TYPE,
      IDENTIFICATION_TYPE,
      validator: {
        phone
      }
    };
  },
  computed: {},
  watch: {
    existingLandlords: {
      handler(val) {
        this.landlordDetails.users = [...val, ...this.removedLandlords];
      },
      deep: true
    },
    landlordDetails: {
      deep: true,
      handler(val) {
        this.$emit("update:landlordData", val);
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    getIdNoLabel: getIdNoLabel,

    init() {
      if (!this._.isEmpty(this.landlordData)) {
        this.landlordDetails = this._.cloneDeep(this.landlordData);
        this.existingLandlords = this._.cloneDeep(this.landlordData.users);
      }
      this.getCountries();
      this.getRelationship();
    },

    async getCountries() {
      try {
        this.isCountryLoading = true;
        let data = await rentAPI.getCountries();
        this.countryOptions = data.map((country) => country.name.common);
        this.isCountryLoading = false;
      } catch (error) {
        this.isCountryLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch country list. Please try again later."
        });
      }
    },
    async getRelationship() {
      try {
        this.isRelationshipLoading = true;
        let data = await rentAPI.getRelationship();
        this.relationshipOptions = data;
        this.isRelationshipLoading = false;
      } catch (error) {
        this.isRelationshipLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch relationship options. Please try again later."
        });
      }
    },

    getLandlordTabLabel(index) {
      return this.existingLandlords[index].name == ""
        ? `Landlord ${index + 1}`
        : this.existingLandlords[index].name;
    },
    addLandlord() {
      this.existingLandlords.push(userDetail);
    },
    removeLandlord(id, index) {
      // Shift active element
      this.activeLandlord = index - 1 < 0 ? index + 1 : index - 1;

      // If existing data, update isDelete
      if (id !== "") {
        // Move object to another array
        this.removedLandlords.push(this.existingLandlords[index]);
        this.removedLandlords[this.removedLandlords.length - 1].isDelete = true;
      }

      this.$nextTick(() => {
        this.$delete(this.existingLandlords, index);
        // Shift back active element if it's first
        this.activeLandlord =
          index - 1 < 0 ? this.activeLandlord - 1 : this.activeLandlord;
      });
    },
    validateFields() {
      let validated = true;

      // Validate all landlords
      for (let index = this.existingLandlords.length - 1; index >= 0; index--) {
        if (!this.$refs.landlord[index].checkValidation()) {
          this.activeLandlord = index;
          validated = false;
        }
      }

      return validated;
    },
    getIsFieldRequired() {
      return this.required ? [required] : [];
    },
    setIsLoading(bool) {
      this.$emit("update:isLoading", bool);
    },
    checkValidation() {
      return (
        this.$refs.taLandlordForm.checkValidation() && this.validateFields()
      );
    },
    saveLandlord() {
      if (this.validateFields()) {
        this.$emit("update", RentTAModel.toUpdateTAOwner(this.landlordDetails));
      }
    }
  }
};
</script>

<style lang="scss"></style>
